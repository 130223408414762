.export-form {
  label {
    font-weight: normal;

    &.export-all {
      font-style: italic;
      margin-left: 15px;
    }
  }

  button {
    margin-top: 15px;
  }
}