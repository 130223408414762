.image-admin-preview {
  margin: 10px 0;

  img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }

  .left, .right {
    float: left;
    max-width: 50%;
  }

  .right {
    padding-left: 5px;
  }

  &::after {
    content: ' ';
    display: block;
    clear: both;
  }
}

#select2-drop {
  max-width: 50vw;
}

#sonata-ba-field-container-sae0ea9285a_translations_fr_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_en_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_de_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_it_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_es_ebayName {
  color: #3c8dbc;

  input {
    color: #3c8dbc;
  }
}