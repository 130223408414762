#index img {
  margin: 100px auto;
  display: block;
}

.image-admin-preview {
  margin: 10px 0;
}

.image-admin-preview img {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.image-admin-preview .left,
.image-admin-preview .right {
  float: left;
  max-width: 50%;
}

.image-admin-preview .right {
  padding-left: 5px;
}

.image-admin-preview::after {
  content: ' ';
  display: block;
  clear: both;
}

#select2-drop {
  max-width: 50vw;
}

#sonata-ba-field-container-sae0ea9285a_translations_fr_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_en_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_de_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_it_ebayName,
#sonata-ba-field-container-sae0ea9285a_translations_es_ebayName {
  color: #3c8dbc;
}

#sonata-ba-field-container-sae0ea9285a_translations_fr_ebayName input,
#sonata-ba-field-container-sae0ea9285a_translations_en_ebayName input,
#sonata-ba-field-container-sae0ea9285a_translations_de_ebayName input,
#sonata-ba-field-container-sae0ea9285a_translations_it_ebayName input,
#sonata-ba-field-container-sae0ea9285a_translations_es_ebayName input {
  color: #3c8dbc;
}

.translate-button {
  margin-bottom: 5px;
}

.export-form label {
  font-weight: normal;
}

.export-form label.export-all {
  font-style: italic;
  margin-left: 15px;
}

.export-form button {
  margin-top: 15px;
}

